@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../public/assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../public/assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../public/assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../public/assets/fonts/Montserrat-Bold.ttf");
}
